import React from "react";

const Privacy = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
      
        <div ><h1  className='j'><span>Privacy Policy</span></h1></div>
        <p>
         
        </p>
      </div>
    </div>
  );
};

export default Privacy;
