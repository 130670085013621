import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Documents from "./pages/Documents";
import Privacy from "./pages/Privacy";
import HomeLayout from "./layout/home-layout";
import Welcomw from "./components/home/Welcome";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Service from "./pages/Service";
import Policies from "./pages/Policies";
import ApplyOne from "./apply/ApplyOne";

import Thanks from "./apply/Thanks";

import Cookies from "js-cookie";
import Preview from "./apply/Preview";
import Payment from "./apply/Payment";
import Cancel from "./apply/Cancel";
import Partialpay from "./apply/Partialpay";
function App() {
  return (
    <>
      <Router >
        <Routes>
          <Route path="/" exact element={<HomeLayout />}>
            <Route path="about" element={<About />} />
            <Route path="term-and-condition" element={<Blog />} />
            <Route path="contact" element={<Contact />} />
            <Route path="welcome" element={<Welcomw />} />
            <Route path="documents" element={<Documents />} />
            <Route path="Service" element={<Service />} />
            <Route path="Policies" element={<Policies />} />
            <Route path="Thanks" element={<Thanks />} />
            <Route path="cancel" element={<Cancel />} />
            <Route path="preview/:id" element={<Preview />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="payment/:id" element={<Payment />} />
            <Route path="Partialpay" element={<Partialpay />} />
            <Route path="Apply" element={<ApplyOne />} />
            <Route path="Apply/:id" element={<ApplyOne />} />
            <Route path="faq" element={<Faq />} />
            <Route index element={<Welcomw />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
