import React from "react";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import { FaLinkedin, FaTwitter, FaWhatsapp, FaFacebook } from "react-icons/fa";
import contimg from "../images/contact.png"
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button } from "react-bootstrap";

const Contact = () => {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <h3>Contact Us</h3>
        <p> Any question or remarks? Just write us a message!</p>
      </div>

      <div className="center1">

        <Card style={{ width: '900px' }} className="border_colo">
          <Card.Body className="card_flex1">

            <div className="map1">
              <a href="mailto:info@esrilankaeta.com" className="contact-design"> Contact Us :- info@esrilankaeta.com  </a>
              {/* <ul className="icons">
                <ul className="row_flex">
                </ul>
              </ul> */}
            </div>


            <div className="map">


              {/* <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
              
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.79804823037466!2d77.2211074719675!3d28.666650054639955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd0039fb19dd%3A0xce4c0785c915cbf3!2s.www.esrilankaeta.com%20%26%20Associates!5e0!3m2!1sen!2sin!4v1706166496562!5m2!1sen!2sin" referrerpolicy="no-referrer-when-downgrade">
                              
                <a href="https://www.maps.ie/population/">Calculate population in area</a></iframe>  */}


            </div>

          </Card.Body>
        </Card>

      </div>


    </div >
  );
};

export default Contact;
