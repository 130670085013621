import { Container } from '@mui/system'
import React from 'react'

function Policies() {
  return (
    <>
      <div className='j'> <h3 className='welcomeText'>Privacy Policy </h3></div>

      <Container>
        <p> The privacy policy describes how <b> www.esrilankaeta.com</b> secure and privacy of any detail or
          information that you provide while you are on this website. e-Visa Srilanka is committed to making
          sure of your privacy. We collect a few details by which you can be recognized while using this
          website, and then you can be assured that it will only be used by this privacy statement. We may
          alter this policy from time to time by updating the content of this page. For usual updates, you may
          check this page time and again.</p>
        <h4> <b> We may collect the following information :- </b></h4>

        <p>
          The e-Visa application will require your Name, Contact Details such as email address and phone
          number to contact you for any other information or some confirmation related to the application and
          also your other personal details will be asked in the form to provide. We need demographic
          information such as address, postal code for processing the application and identify your location.
          We may need other information applicable to the completion of our services.
        </p>

        <h4> <b> Purpose of collecting the information :- </b></h4>

        <p>
          We require your information to know your needs so that we can provide you with a better service.
          We may use the information to get in touch with you for feedback and to improve our services. We
          do not send any emails regarding offers or promotions however we do follow up through emails or
          phone. We may require using your information to contact you for market research purposes and
          your feedback. We may contact you by email or phone.
        </p>


        <h4> <b> Security Measures :- </b></h4>
        <p>
          We are committed to look after all your valuable and confidential information. To prevent your
          information from any unofficial access or breach we have put in place appropriate checks in the form
          of physical, electronic and managerial procedures to secure the information we collect online
        </p>

        <h4> <b> How we control your personal Information :- </b> </h4>
        <p> We save your details only for processing the visa application and not for promotion purposes; you
          may email to us in related to e-Visa application at any time on <b> info@esrilankaeta.com </b> We never sell
          or share your personal information with any third party except the Government of Srilanka as and
          when asked by the Sri Lanka law to do so. If there is any issue, please feel free to write to us by
          email, if you believe that any of your information held by us is incorrect or incomplete. We will make
          the necessary corrections</p>


        <h4> <b> Cookies www.esrilankaeta.com :- </b></h4>
        <p> We use cookies to identify you and other customers who are visiting our site. That allows us to
          modify your experience on our website. Moreover, for the marketing purpose, we use the
          remarketing or similar audiences feature on our website by Google&#39;s Cookies and the Double Click&#39;s
          remarketing pixels. The third-party vendors including Google can use cookies to serve ads based on
          your past visits to our website. You may need to have cookies &quot;turned on&quot; or allow in the browser
          you use in order to register. However, if you don&#39;t want to use Cookies and store your information on
          our website, you can select to disable cookies by visiting Google&#39;s Ads Settings or visiting the
          Network Advertising Initiative opt-out page. You are still able to navigate the site after the cookies

          have been disabled but some info on our website will not be visible. And to opt-out of Double Click&#39;s
          use of cookies by visiting the Double Click opt-out page. To search out more about how Google uses
          any information it collects, please visit http://www.google.com/policies/privacy/ads/ Cookies do not
          store any personal information. All the information is completely confidential and is never sell or
          seen outside of the company. <b> www.esrilankaeta.com </b> may display links or advertisements to other
          sites and companies that may also use cookies. In such cases, we cannot be held liable for any or
          all information that these parties collect through the use of cookies. You are hereby advised to
          familiarize yourself with privacy policies and information sharing standards of these sites, as they
          may be different from the policies and standards of <b> www.esrilankaeta.com</b></p>

        <h4> <b> Updating, Changing or Modify Information :- </b></h4>
        <p> If your personal information with us needs to be altered or updated. Once the approval letter is
          issued by Sri Lanka Immigration Department, we are unable to update or alter any information;
          hence you have to apply for a new visa application.</p>

        <h4> <b>System information :- </b></h4>
        <p> <b> www.esrilankaeta.com</b> has the right to collect and store such information as the IP address, browser
          type or operating system type. All the data is highly confidential and will be used completely for
          system administration purposes. This information helps diagnose problems, monitor traffic and site
          usage.</p>

        <h4> <b>Policy Amendments :- </b></h4>
        <p> We have the right to update or modify the privacy policy anytime by posting a new version on our
          website. You should go through this page frequently to ensure you are alert of these changes.</p>

        <h4><b> Third party websites :- </b></h4>
        <p> The website contains links to other websites. We are not liable for the privacy policies or practices of
          third-party websites.</p>

        <h4><b> Payment through third party (Financial Transactions) :- </b></h4>
        <p> On the website, we have integrated online payment tools, but here we acknowledge the shaft of the
          Bank as RazorPay pocket electronic payment. For the easiness of payment, we accept credit cards,
          RazorPay and direct bank transfer online. All credit card information, RazorPay account is required to pay
          visa fees and will be transferred to our partners such as RazorPay.com, and all your data is protected.
          We advise that you read the terms and conditions of the policy very carefully.</p>


        <h4><b> Noted :- </b></h4>
        <p> We are not responsible for the privacy policies or practices of third-party payment gateway.</p>
        <h4> <b> Contact us :- </b></h4>
        <p> If you have any doubts or questions about this privacy policy, please contact us :
          <b> info@esrilankaeta.com </b>By accessing this site and using our services, you will agree with our Policy
          and Terms of Service. If you don&#39;t agree with all or any of the terms of this Policy, please do not use
          this site.</p>

      </Container>

      <div className='j'><h3 className='welcomeText'> Refund Policy </h3></div>

      <Container>
        <h3> <b> Refund and cancellation of the e-Visa visa service fees :- </b></h3>
        <p> <b> www.esrilankaeta.com</b> provides its users an option to raise a request for refund/cancellation in case the applicant wants to discontinue our services. The applicants can apply for refund within one hour of submission of application subject to the following condition: -  </p>
        <ul>
          <li> No refund/cancellation in case the travel is within 24-72 hours. </li>
          <li> If we received your request for cancellation/refund within One hour after the submission of the application, we will refund the payment after deduction of 25% towards the cancellation charges and in urgent travel cases, we will refund the payment after deduction of 50 % towards the cancellation charges.  </li>
          <li> If you wish to request a refund, email us at <b> info@esrilankaeta.com </b> Stating the reason for the request with your full name (as appears in your passport), your unique transaction ID and the registered email used while submission of the application. </li>
          <li> All refund requests will be processed within 3 to 4 working days. </li>
        </ul>
      </Container>
    </>
  )
}

export default Policies