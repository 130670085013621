import { React } from "react";
import { Container } from "react-bootstrap";
import Carsol from "../../components/home/Carsol"
import cccimg from "../../images/srilanka_visa_page.jpg"
import Table from 'react-bootstrap/Table';
function Welcomw() {
  return (
    <>
      <Carsol />
      <div className="pagesa4kms">
        <Container >

          <div ><h2 id="lkj"> Welcome to the Sri Lanka eVisa </h2></div>

          <p> Experience the convenience of obtaining your visa online for your trip to the beautiful island of
            Sri Lanka. Whether you&#39;re traveling for <b> tourism or business</b>, our eVisa system simplifies the
            application process, saving you time and effort.
          </p>

          <b><p id="namo"> What is Online Sri Lanka eVisa?</p> </b>
          <p>The <b> Online Sri Lanka eVisa</b> is an electronic travel authorization that allows eligible travelers to
            enter Sri Lanka for tourism, business, or transit purposes. The eVisa system simplifies the visa
            application process by allowing travelers to apply, pay, and receive their visa online, eliminating
            the need to visit a consulate or embassy.
          </p>

          <p> The eVisa program was introduced by the Government of <b> Sri Lanka</b> to promote tourism and
            facilitate easier access for visitors. It offers various types of visas, including tourist visas,
            business visas, and transit visas, depending on the purpose of the visit.
          </p>

          <p> To apply for an <b> Online Sri Lanka eVisa</b>, travelers need to complete an online application form,
            provide necessary documents such as a passport, passport-sized photograph and pay the
            required visa fee. The application is usually processed within a few days, and if approved, the
            eVisa is sent to the applicant via email.
          </p>

          <p> The <b> Online Sri Lanka eVisa </b>system enhances the efficiency of the visa application process,
            promotes tourism, and contributes to the economic development of Sri Lanka by attracting more
            visitors to the country.
          </p>

          <p id="namo"><b> Types of Sri Lanka eVisas</b> </p>
          <b><p>1. Tourist Sri Lanka eVisa:</p> </b>
          <ul>
            <li>Valid for short-term visits for sightseeing, visiting friends and family, and holidaying. </li>
            <li> Available for single, double and multiple entries.</li>
          </ul>


          <b><p> 2. Business Sri Lanka eVisa:</p></b>
          <ul>
            <li>Ideal for business meetings, conferences, and trade negotiations. </li>
            <li> Available for single, double, and multiple entries.</li>
          </ul>


        </Container>
        <div ><h2 id="kk" > Eligible Countries for Sri Lanka eVisa </h2></div>
        <Container>
          <div className="">
            <p> The <b>Sri Lanka eVisa </b> is available to citizens of many countries around the world. Travelers from
              the following countries are eligible to apply for an eVisa to visit Sri Lanka for tourism or business
              purposes:</p>
            <Table className="table">
              <tbody>
                <tr>
                  <td>1. Afghanistan</td>
                  <td>2. Albania</td>
                  <td>3. Algeria</td>
                </tr>
                <tr>
                  <td>4. American Samoa</td>
                  <td>5. Andorra</td>
                  <td>6. Angola</td>
                </tr>
                <tr>
                  <td>7. Antarctica</td>
                  <td>8. Antigua and Barbuda</td>
                  <td>9. Argentina</td>
                </tr>
                <tr>
                  <td>10. Armenia</td>
                  <td>11. Aruba</td>
                  <td>12. Australia</td>
                </tr>
                <tr>
                  <td>13. Austria</td>
                  <td>14. Azerbaijan</td>
                  <td>15. Bahamas</td>
                </tr>
                <tr>
                  <td>16. Bahrain</td>
                  <td>17. Bangladesh</td>
                  <td>18. Barbados</td>
                </tr>
                <tr>
                  <td>19. Belarus</td>
                  <td>20. Belgium</td>
                  <td>21. Belize</td>
                </tr>
                <tr>
                  <td>22. Benin</td>
                  <td>23. Bermuda</td>
                  <td>24. Bhutan</td>
                </tr>
                <tr>
                  <td>25. Bolivia</td>
                  <td>26. Botswana</td>
                  <td>27. Brazil</td>
                </tr>
                <tr>
                  <td>28. British Virgin Islands</td>
                  <td>29. Brunei</td>
                  <td>30. Bulgaria</td>
                </tr>
                <tr>
                  <td>31. Burkina Faso</td>
                  <td>32. Burundi</td>
                  <td>33. Cape Verde</td>
                </tr>
                <tr>
                  <td>34. Cayman Islands</td>
                  <td>35. Central African Republic</td>
                  <td>36. Chad</td>
                </tr>
                <tr>
                  <td>37. Chile</td>
                  <td>38. China</td>
                  <td>39. Christmas Island</td>
                </tr>
                <tr>
                  <td>40. Cocos Islands</td>
                  <td>41. Colombia</td>
                  <td>42. Comoros</td>
                </tr>
                <tr>
                  <td>43. Cook Islands</td>
                  <td>44. Costa Rica</td>
                  <td>45. Côte d'Ivoire</td>
                </tr>
                <tr>
                  <td>46. Croatia</td>
                  <td>47. Cuba</td>
                  <td>48. Curaçao</td>
                </tr>
                <tr>
                  <td>49. Cyprus</td>
                  <td>50. Czech Republic</td>
                  <td>51. Denmark</td>
                </tr>
                <tr>
                  <td>52. Djibouti</td>
                  <td>53. Dominica</td>
                  <td>54. Dominican Republic</td>
                </tr>
                <tr>
                  <td>55. Ecuador</td>
                  <td>56. Egypt</td>
                  <td>57. El Salvador</td>
                </tr>
                <tr>
                  <td>58. Equatorial Guinea</td>
                  <td>59. Eritrea</td>
                  <td>60. Estonia</td>
                </tr>
                <tr>
                  <td>61. Eswatini</td>
                  <td>62. Ethiopia</td>
                  <td>63. Falkland Islands</td>
                </tr>
                <tr>
                  <td>64. Faroe Islands</td>
                  <td>65. Fiji</td>
                  <td>66. Finland</td>
                </tr>
                <tr>
                  <td>67. France</td>
                  <td>68. France Metropolitan</td>
                  <td>69. French Guiana</td>
                </tr>
                <tr>
                  <td>70. French Polynesia</td>
                  <td>71. Gabon</td>
                  <td>72. Gambia</td>
                </tr>
                <tr>
                  <td>73. Georgia</td>
                  <td>74. Germany</td>
                  <td>75. Gibraltar</td>
                </tr>
                <tr>
                  <td>76. Greece</td>
                  <td>77. Greenland</td>
                  <td>78. Grenada</td>
                </tr>
                <tr>
                  <td>79. Guadeloupe</td>
                  <td>80. Guam</td>
                  <td>81. Guatemala</td>
                </tr>
                <tr>
                  <td>82. Guernsey</td>
                  <td>83. Guinea</td>
                  <td>84. Guinea-Bissau</td>
                </tr>
                <tr>
                  <td>85. Guyana</td>
                  <td>86. Haiti</td>
                  <td>87. Honduras</td>
                </tr>
                <tr>
                  <td>88. Hong Kong</td>
                  <td>89. Hungary</td>
                  <td>90. Iceland</td>
                </tr>
                <tr>
                  <td>91. India</td>
                  <td>92. Indonesia</td>
                  <td>93. Iran</td>
                </tr>
                <tr>
                  <td>94. Iraq</td>
                  <td>95. Ireland</td>
                  <td>96. Isle of Man</td>
                </tr>
                <tr>
                  <td>97. Israel</td>
                  <td>98. Italy</td>
                  <td>99. Jamaica</td>
                </tr>
                <tr>
                  <td>100. Japan</td>
                  <td>101. Jersey</td>
                  <td>102. Jordan</td>
                </tr>
                <tr>
                  <td>103. Kazakhstan</td>
                  <td>104. Kenya</td>
                  <td>105. Kiribati</td>
                </tr>
                <tr>
                  <td>106. Kosovo</td>
                  <td>107. Kuwait</td>
                  <td>108. Kyrgyzstan</td>
                </tr>
                <tr>
                  <td>109. Laos</td>
                  <td>110. Latvia</td>
                  <td>111. Lebanon</td>
                </tr>
                <tr>
                  <td>112. Lesotho</td>
                  <td>113. Liberia</td>
                  <td>114. Libya</td>
                </tr>
                <tr>
                  <td>115. Liechtenstein</td>
                  <td>116. Lithuania</td>
                  <td>117. Luxembourg</td>
                </tr>
                <tr>
                  <td>118. Macao</td>
                  <td>119. Macedonia</td>
                  <td>120. Madagascar</td>
                </tr>
                <tr>
                  <td>121. Malawi</td>
                  <td>122. Malaysia</td>
                  <td>123. Maldives</td>
                </tr>
                <tr>
                  <td>124. Mali</td>
                  <td>125. Malta</td>
                  <td>126. Marshall Islands</td>
                </tr>
                <tr>
                  <td>127. Martinique</td>
                  <td>128. Mauritania</td>
                  <td>129. Mauritius</td>
                </tr>
                <tr>
                  <td>130. Mayotte</td>
                  <td>131. Mexico</td>
                  <td>132. Micronesia</td>
                </tr>
                <tr>
                  <td>133. Moldova</td>
                  <td>134. Monaco</td>
                  <td>135. Mongolia</td>
                </tr>
                <tr>
                  <td>136. Montenegro</td>
                  <td>137. Montserrat</td>
                  <td>138. Morocco</td>
                </tr>
                <tr>
                  <td>139. Mozambique</td>
                  <td>140. Myanmar</td>
                  <td>141. Namibia</td>
                </tr>
                <tr>
                  <td>142. Nauru</td>
                  <td>143. Nepal</td>
                  <td>144. Netherlands</td>
                </tr>
                <tr>
                  <td>145. Netherlands Antilles</td>
                  <td>146. New Caledonia</td>
                  <td>147. New Zealand</td>
                </tr>
                <tr>
                  <td>148. Nicaragua</td>
                  <td>149. Niger</td>
                  <td>150. Nigeria</td>
                </tr>
                <tr>
                  <td>151. Niue</td>
                  <td>152. Norfolk Island</td>
                  <td>153. North Korea</td>
                </tr>
                <tr>
                  <td>154. Northern Mariana Islands</td>
                  <td>155. Norway</td>
                  <td>156. Oman</td>
                </tr>
                <tr>
                  <td>157. Pakistan</td>
                  <td>158. Palau</td>
                  <td>159. Panama</td>
                </tr>
                <tr>
                  <td>160. Papua New Guinea</td>
                  <td>161. Paraguay</td>
                  <td>162. Peru</td>
                </tr>
                <tr>
                  <td>163. Philippines</td>
                  <td>164. Pitcairn Islands</td>
                  <td>165. Poland</td>
                </tr>
                <tr>
                  <td>166. Portugal</td>
                  <td>167. Puerto Rico</td>
                  <td>168. Qatar</td>
                </tr>
                <tr>
                  <td>169. Republic of the Congo</td>
                  <td>170. Réunion</td>
                  <td>171. Romania</td>
                </tr>
                <tr>
                  <td>172. Russia</td>
                  <td>173. Rwanda</td>
                  <td>174. Saint Barthélemy</td>
                </tr>
                <tr>
                  <td>175. Saint Helena</td>
                  <td>176. Saint Lucia</td>
                  <td>177. Saint Martin</td>
                </tr>
                <tr>
                  <td>178. Samoa</td>
                  <td>179. San Marino</td>
                  <td>180. Saudi Arabia</td>
                </tr>
                <tr>
                  <td>181. Senegal</td>
                  <td>182. Serbia</td>
                  <td>183. Seychelles</td>
                </tr>
                <tr>
                  <td>184. Sierra Leone</td>
                  <td>185. Singapore</td>
                  <td>186. Sint Maarten</td>
                </tr>
                <tr>
                  <td>187. Slovakia</td>
                  <td>188. Slovenia</td>
                  <td>189. Solomon Islands</td>
                </tr>
                <tr>
                  <td>190. Somalia</td>
                  <td>191. South Africa</td>
                  <td>192. South Georgia</td>
                </tr>
                <tr>
                  <td>193. South Korea</td>
                  <td>194. South Sudan</td>
                  <td>195. Spain</td>
                </tr>
                <tr>
                  <td>196. Sri Lanka</td>
                  <td>197. Sudan</td>
                  <td>198. Suriname</td>
                </tr>
                <tr>
                  <td>199. Sweden</td>
                  <td>200. Switzerland</td>
                  <td>201. Syria</td>
                </tr>
                <tr>
                  <td>202. Taiwan</td>
                  <td>203. Tajikistan</td>
                  <td>204. Tanzania</td>
                </tr>
                <tr>
                  <td>205. Thailand</td>
                  <td>206. Timor-Leste</td>
                  <td>207. Togo</td>
                </tr>
                <tr>
                  <td>208. Tokelau</td>
                  <td>209. Tonga</td>
                  <td>210. Trinidad and Tobago</td>
                </tr>
                <tr>
                  <td>211. Tunisia</td>
                  <td>212. Turkey</td>
                  <td>213. Turkmenistan</td>
                </tr>
                <tr>
                  <td>214. Tuvalu</td>
                  <td>215. Uganda</td>
                  <td>216. Ukraine</td>
                </tr>
                <tr>
                  <td>217. United Arab Emirates</td>
                  <td>218. United Kingdom</td>
                  <td>219. United States</td>
                </tr>
                <tr>
                  <td>220. Uruguay</td>
                  <td>221. Uzbekistan</td>
                  <td>222. Vanuatu</td>
                </tr>
                <tr>
                  <td>223. Vatican City</td>
                  <td>224. Venezuela</td>
                  <td>225. Vietnam</td>
                </tr>
                <tr>
                  <td>226. Virgin Islands</td>
                  <td>227. Wallis and Futuna</td>
                  <td>228. Western Sahara</td>
                </tr>
                <tr>
                  <td>229. Yemen</td>
                  <td>230. Zambia</td>
                  <td>231. Zimbabwe</td>
                </tr>
              </tbody>


            </Table>
          </div>
        </Container>

        <Container>
          <div ><h2 id="as2df" > How to Apply Sri Lanka eVisa </h2></div>

          <div className="fixed-height-image-container">
            <img src={cccimg} alt="Image description" className="fixed-height-image" />
          </div>
          <div ><h2 id="bhytr5" > Why Choose Sri Lanka eVisa </h2></div>
          <ul>
            <li> Convenience: Apply from anywhere in the world without visiting an embassy or
              consulate.
            </li>
            <li> Time-Saving: Faster processing times mean you can plan your trip without delay.</li>
            <li> Accessibility: Available 24/7 online, with support in multiple languages.</li>
          </ul>
          <div ><h2 id="bhytr5" > Important Information </h2></div>

          <ul>
            <li> Ensure your passport is valid for at least 6 months from the date of entry into Sri Lanka.</li>
            <li> Verify your eligibility for the eVisa before applying.</li>
            <li> Double-check all application details to avoid processing issues.</li>
            <li> If the Sri Lankan eVisa application form is rejected by the Sri Lankan government
              authorities due to furnishing by wrong details by the visitors, our website
              <b> www.esrilankaeta.com</b> cannot be held responsible in any way. </li>
          </ul>

        </Container>

      </div>



      <div id="lko09">

        <p>
          <b>Disclaimer:- </b> {" "}
          <b> www.esrilankaeta.com </b> is an independent travel advisor and is not affiliated with or endorsed by
          any government agency or organization. By utilizing our services for the Sri Lanka e-Visa
          application, you acknowledge that <b> www.esrilankaeta.com</b> acts solely as a facilitator, and the
          approval of the e-Visa is subject to the discretion of Sri Lanka immigration authorities.
          Applicants are responsible for the accuracy of the information provided, and any discrepancies
          may lead to delays or rejections. Our company cannot guarantee specific processing times or
          the approval of the visa. It is the applicant&#39;s responsibility to verify their eligibility, adhere to the
          approved travel dates, and plan their itinerary accordingly. <b> www.esrilankaeta.com</b> is not liable for
          any issues arising from the e-Visa application process, including but not limited to delays,
          rejections, or changes in travel plans. The refund policy is outlined separately, and applicants
          are advised to review it before submitting an application. <b> www.esrilankaeta.com</b> reserves the
          right to modify these terms without prior notice.
        </p>

      </div>


    </>
  )
}




export default Welcomw