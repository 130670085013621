
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, baseURL } from '../utils/api';
function Preview() {
  const { id } = useParams();
  const [formdata, setformdata] = useState({})
  const navigate = useNavigate()
  console.log("baseURL", baseURL)
  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`)
      if (response.data && response.data.status) {
        console.log("🚀 ~ fetchApplicationDetail ~ response:", response.data)
        setformdata(response.data.data)
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong")
    }
  }, [id])
  useEffect(() => {
    fetchApplicationDetail()
  }, [fetchApplicationDetail])
  const handleEdit = () => {
    navigate(`/Apply/${id}`)
  }

  const Gopayment = () => {
    navigate(`/payment/${id}`)
  }
  return (
    <> {formdata &&

      <div className='gsrilanka'>
        <div className="centerCol">
          <div className="welcomeText">
            <Container className='pagesa4'>
              <h3> Preview Your Application  </h3>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> <b> Application Number </b> </Form.Label>
                </Col>
                <Col sm={4}>
                  <p> <b> {formdata.sku_id}</b> </p>
                </Col>
              </Row>

              <Container className='pagesa4'>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Your  Nationality *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.nationality}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Country Of Residence *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.country_of_residence}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Visa Type *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.visa_type}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Visa Category *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.visa_categroy}</b> </p>
                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Purpose of Visit  *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.purpose_of_visit}</b> </p>
                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Sub Visa Type *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.sub_visa_type}</b> </p>
                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Type of Travel Document  *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.type_of_travel_doc}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Email *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.email}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Country Code  *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.country_code}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Mobile/Cell Phone Number *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.mobile_number}</b> </p>
                  </Col>
                </Row>

                <div className="formvisa_apply">
                  Passport Details
                </div>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Passport Number *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.passport_number}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Passport Issued Date *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.passport_issued_date}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>

                    <Form.Label htmlFor="basic-url">Passport Expiry Date *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.passport_expiry_date}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Date of Birth *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.date_of_birth}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Title *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.title}</b> </p>
                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Surname/Family Name </Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.surname}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Other/Given Name  *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.other_name}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Passport Place of Issue  *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.passport_place_of_issue}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Place of Birth  *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.place_of_birth}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Marital Status *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.marital_status}</b> </p>


                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Gender *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.gender}</b> </p>


                  </Col>
                </Row>



                <div className="formvisa_apply">
                  Travel Information
                </div>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Date of Arrival in Sri Lanka *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.date_of_arrivalIn_srilanka}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">  Port of Entry in Sri Lanka *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.port_of_entry_in_srilanka}</b> </p>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> During Your Stay in Sri Lanka, Your Place of Stay will be  *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.stay_in_srilanka}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Date of Proposed Departure from Sri Lanka *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.date_of_propos_dept_srilanka}</b> </p>

                  </Col>
                </Row>




                {formdata && formdata.visa_categroy == "Business" ?
                  <div>
                    <Row className="justify-content-md-center m-2">
                      <Col sm={5}>
                        <Form.Label htmlFor="basic-url"> Company Details in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                      </Col>
                      <Col sm={4}>
                        <p> <b> {formdata.srilanka_comp_details}</b> </p>

                      </Col>
                    </Row>

                    <Row className="justify-content-md-center m-2">
                      <Col sm={5}>
                        <Form.Label htmlFor="basic-url"> Company Zip in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                      </Col>
                      <Col sm={4}>
                        <p> <b> {formdata.company_zip_details}</b> </p>

                      </Col>
                    </Row>
                    <Row className="justify-content-md-center m-2">
                      <Col sm={5}>
                        <Form.Label htmlFor="basic-url"> Company City  in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                      </Col>
                      <Col sm={4}>
                        <p> <b> {formdata.company_city}</b> </p>

                      </Col>
                    </Row> </div>
                  : null}

                <div className="formvisa_apply">
                  Contact Details
                </div>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Address Line 1 in Sri Lanka *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.address_line1}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Address Line 2 in Sri Lanka *</Form.Label>
                  </Col>
                  <Col sm={4}>

                    <p> <b> {formdata.address_line2}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> City *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.city}</b> </p>

                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> State *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.state}</b> </p>

                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Zip/Postal Code *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p> <b> {formdata.postal_code}</b> </p>

                  </Col>
                </Row>

                <div className="formvisa_apply">
                  Required Document
                </div>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Passport Size Colored Photo *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <div className='preview_img'>

                      <img src={`${baseURL}/uploads/${formdata.passport_size_photo}`} alt='passport_size_photo' />
                    </div>

                  </Col>
                </Row>


                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Passport Bio Page *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <div className='preview_img'>


                      <img src={`${baseURL}/uploads/${formdata.passport_bio_page}`} alt='passport_bio_page' />
                    </div>
                  </Col>
                </Row>


                {formdata && formdata.visa_categroy === "Business" ?
                  <div>
                    <Row className="justify-content-md-center m-2">
                      <Col sm={5}>
                        <Form.Label htmlFor="basic-url"> Invitation Letter from Host Company in Sri Lanka *</Form.Label>
                      </Col>
                      <Col sm={4}>
                        <div className='preview_img'>
                          <img src={`${baseURL}/uploads/${formdata.invitation_latter}`} />
                        </div>
                      </Col>
                    </Row>

                  </div>
                  : null}

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Button onClick={handleEdit} > Edit </Button>
                  </Col>
                  <Col sm={4}>
                    <Button onClick={Gopayment} > Next To Pay </Button>
                  </Col>

                </Row>
              </Container>
            </Container>
          </div>
        </div>
      </div>

    }

    </>
  )
}

export default Preview