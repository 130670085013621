import React from 'react'
import { Container } from 'react-bootstrap'

function Blog() {
  return (
    <>
      <div className='j'><h3 className='welcomeText'>Terms &amp; Conditions before confirming your eVisa
        application </h3></div>

      <Container>

        <p> I hereby certify that I have read and understood all the terms and conditions set forth in this
          application and all the information I have furnished in this form are true and accurate to the best of
          my knowledge and belief.</p>
        <p> I understand that any false or misleading statement may result in the permanent refusal of an eVisa
          or denial of entry into Sri Lanka. I understand that possession of an eVisa does not entitle me to
          enter Sri Lanka upon arrival at a port of entry if I am found inadmissible.</p>

        <h3> 1. All Evisa holders should be able to prove:</h3>
        <ul>
          <li> A round trip ticket to show at the port of entry in Sri Lanka (only if you are traveling by air).</li>
          <li> Evidence of sufficient funds to cover your expenses in Sri Lanka. </li>
        </ul>
        <h3> 2. Employment:</h3>
        <ul>
          <li> Holder of eVisa, should not engage in any form of employment, paid or unpaid, or
            in any trade or business other than specified in the eVisa, during the period of his/her stay in Sri
            Lanka</li>
        </ul>

        <h3> 3. Fees and Payments:</h3>
        <ul>
          <li> You agree that your credit card(s) will be billed immediately after
            submission of your application. Requirements and Fees related to processing of your application are
            subject to change from time to time and you agree that the eVisa processing fee once paid by you is
            not refundable and not transferable..</li>
        </ul>
        <h3> 4. Accuracy: </h3>
        <ul>

          <li> By submitting your application, you do certify that the information provided by you is
            true and correct.</li>
        </ul>
        <h3> 5. Limitations of use:</h3>
        <ul>
          <li>
            You may not use this site for any purpose other than the intended purpose.
          </li>
        </ul>

        <h3> 6. Application Submitting Terms</h3>
        <ul>
          <li> You must enter the passport number correctly</li>
          <li>You must enter nationality correctly as appears in the travel document </li>
          <li> You must enter your date of birth correctly</li>
          <li> You must recheck / crosscheck above information to ascertain that they have been entered correctly.
            You are required to pay for the visa again on arrival in case of any inconstancy in above particulars.</li>
          <li> You must make sure that the same travel document which was used for applying online visa
            is used for entering into Sri Lanka. In the event you produce a travel document other than the
            one used for applying online visa, you are required to pay again on arrival for the visa.</li>
        </ul>
        <h3> 7. On pressing &quot;Pay Now&quot;:</h3>


        <p>  The application will be redirected to Payment Gateway to pay the visa
          fee and will be outside the control of Visa Online Application. The responsibility of security of
          transaction process and details on payment page will be of Payment gateway. Bank Payment
          Gateway accepts both OTP (One Time Password) and non-OTP transactions.
          In case of any issue, please contact your Bank. Please note that your application for e-Visa will not
          be submitted without fee payment. It should be done prior to 4 days of Journey date.</p>
      </Container>
    </>
  )
}

export default Blog