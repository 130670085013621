import React, { useEffect } from 'react';
function Thanks() {

  return (
   <> 
    <div className="thank-you-page">
      <h1 className="thank-you-heading">Thank You!</h1>


    </div>
   </>
  )
}

export default Thanks 
