import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom"; 

function Partialpay() {  
  const navigate =  useNavigate()
  const[application,setApplication] = useState("")
  const submit =()=>{
    if(!application || application.length<4){
      alert("please enter valid application no.")
      return
    }
    navigate(`/Apply/${application}`)

  }

  return (
   <> 
   <div className='partialpay'>  
   <Container className='pagesa4'> 
      <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Enter your Application Number  *</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                       placeholder="Enter Application Number "
                      aria-label="Application"
                      name='Application'
                      aria-describedby="basic-addon1"
                      value={application}
                      onChange={(e)=>setApplication(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                  </Col>
                  <Col sm={4}>
                <Button onClick={submit}> Submit</Button>
                  </Col>
                </Row>
                </Container>

   </div> 


 
   
   
   
   </>
  )
}

export default Partialpay