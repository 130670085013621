import React from 'react'

function Cancel() {
  return (
    <div className="thank-you-page">
      <h1 className="thank-you-heading">cancel</h1>


    </div>
  )
}

export default Cancel