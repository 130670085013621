import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import img34 from "../../images/kmss.jpg"
function Carsol() {
  return (
    <>
      <div className='borderxdf'>
        <div className='image-container'>
          <img src={img34} alt='#' className='background-image' />
          <Container className='cont_btn'>
            <Row>
              <Link to="/Apply" className='butn_appy1231'>
                <Col>
                  <h4 className='white'>Apply Tourist E-Visa</h4>
                </Col>
              </Link>
              <Link to="/Apply" className='butn_appy1231'>
                <Col>
                  <h4 className='white'>Apply Business E-Visa</h4>
                </Col>
              </Link>
              <Link to="/Partialpay" className='butn_appy1231'>
                <Col>
                  <h4 className='white'>Visa Payment</h4>
                </Col>
              </Link>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Carsol