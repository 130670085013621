
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { api, uploadFile } from '../utils/api';
import { Link, useParams } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
function ApplyOne() {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isBusinessVisa, setBusinessVisa] = useState(false);
  const [document, setdocument] = useState({
    passport_size_photo: "",
    passport_bio_page: "",
    invitation_latter: "",
    accommodation_proof: "",
    travel_plan: "",
  });
  const [touristOptions] = useState([,
    "M.I.C.E Tourist(Meetings, Incentives, Conference & Exhibitions / Events)",
    "Medical treatment including Ayurvedic(herbal)",
    "Participate in Art, Music, and Dance Events",
    "Participate in Pilgrimages",
    "Participate in Sport Events",
    "Participate in Weddings",
    "Visiting Friends and Relatives",
    "Sightseeing or Holidaying"
  ])
  const [businessOptions] = useState([
    "PARTICIPATE IN BUSINESS MEETINGS AND NEGOTIATIONS",
    "Short term training programs"
  ])

  const [touristVisaTime] = useState({
    "Tourist_Visa_30_Days": "Tourist Visa 30 Days",
    "Standard_Visitor_Visa_6_Months_Each_Stay_Resticated_Upto_60_Days": "Standard Visitor Visa(6 months) each stay restricted up to 60 days",
    "one_Year_Multiple_Entry_Tourist_Visa_Each_Stay_Resticated_Upto_90_Days": "1 Year Multiple Entry Tourist Visa each stay restricted upto 90 days",
    "two_Years_Multiple_Entry_Tourist_Visa_Each_Stay_Resticated_Upto_180_Days": "2 Years Multiple Entry Tourist Visa each stay restricted upto 180 days"
  })

  const [businessVisaTime] = useState({
    "Standard_Visitor_Visa_6_Months_Each_Stay_Resticated_Upto_60_Days_bussiness": "Standard Business Visa(6 months) each stay restricted up to 60 days",
    "one_Year_Multiple_Entry_business_Visa_Each_Stay_Resticated_Upto_90_Days": "1 Year Multiple Entry Business Visa each stay restricted upto 90 days",
    "two_Years_Multiple_Entry_business_Visa_Each_Stay_Resticated_Upto_180_Days": "2 Years Multiple Entry Business Visa each stay restricted upto 180 days"
  })

  const handleCheckboxChange1 = (e) => {
    setIsChecked1(e.target.checked);
  };
  const handleVisaSelectChange = (event) => {
    const { value } = event.target;
    formik.handleChange(event);
    setBusinessVisa(value === 'Business');
  };
  const { id } = useParams()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      apply: "www.esrilankaeta.com",
      nationality: "",
      country_of_residence: "",
      visa_type: "",
      visa_categroy: "",
      purpose_of_visit: "",
      sub_visa_type: "",
      type_of_travel_doc: "",
      email: "",
      country_code: "",
      mobile_number: "",
      passport_number: "",
      passport_issued_date: "",
      passport_expiry_date: "",
      date_of_birth: "",
      title: "",
      surname: "",
      other_name: "",
      passport_place_of_issue: "",
      place_of_birth: "",
      marital_status: "",
      gender: "",
      date_of_arrivalIn_srilanka: "",
      port_of_entry_in_srilanka: "",
      stay_in_srilanka: "",
      date_of_propos_dept_srilanka: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      // passport_size_photo: "",
      // passport_bio_page: "",
      // invitation_latter: "",
      // accommodation_proof: "",
      // travel_plan: "",
      valid_residence_visa: "",
      currently_in_sri_lanka: "",
      multiple_entry_visa: "",
      srilanka_comp_details: "",
      company_zip_details: "",
      company_city: "",

    },
    onSubmit: values => {
      if (!id) submitApplication({ ...values, ...document })
      else updateApplication({ ...values, ...document })
    },
  });

  const submitApplication = async (values) => {
    try {
      let response = await api.post("/api/srilanka/create-application", values,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.data && response.data.success) {
        alert(response.data.message)
        navigate(`/preview/${response.data.data.sku_id}`)
      }
      else {
        alert("something went wrong")
        // alert(response.data.message || "something went wrong")
      }
    } catch (error) {
      console.log("🚀 ~ submitApplication ~ error:", error)
      // alert(error.response.data.message || "something went wrong")
      alert("something went wrong")

    }
  }

  const updateApplication = async (values) => {
    try {
      let response = await api.put(`/api/srilanka/update-application/${id}`, values)
      if (response.data && response.data.status) {
        alert(response.data.message)
        navigate(`/preview/${response.data.data.sku_id}`)
      }
      else {
        alert(response.data.message || "something went wrong")
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong")
    }
  }

  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`)
      if (response.data && response.data.status) {
        let data = response.data.data
        formik.setValues({
          apply: data.apply,
          nationality: data.nationality,
          country_of_residence: data.country_of_residence,
          visa_type: data.visa_type,
          visa_categroy: data.visa_categroy,
          purpose_of_visit: data.purpose_of_visit,
          sub_visa_type: data.sub_visa_type,
          type_of_travel_doc: data.type_of_travel_doc,
          email: data.email,
          country_code: data.country_code,
          mobile_number: data.mobile_number,
          passport_number: data.passport_number,
          passport_issued_date: data.passport_issued_date,
          passport_expiry_date: data.passport_expiry_date,
          date_of_birth: data.date_of_birth,
          title: data.title,
          surname: data.surname,
          other_name: data.other_name,
          passport_place_of_issue: data.passport_place_of_issue,
          place_of_birth: data.place_of_birth,
          marital_status: data.marital_status,
          gender: data.gender,
          date_of_arrivalIn_srilanka: data.date_of_arrivalIn_srilanka,
          port_of_entry_in_srilanka: data.port_of_entry_in_srilanka,
          stay_in_srilanka: data.stay_in_srilanka,
          date_of_propos_dept_srilanka: data.date_of_propos_dept_srilanka,
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          city: data.city,
          state: data.state,
          postal_code: data.postal_code,
          passport_size_photo: data.passport_size_photo,
          passport_bio_page: data.passport_bio_page,
          invitation_latter: data.invitation_latter,
          accommodation_proof: data.accommodation_proof,
          travel_plan: data.travel_plan,
          valid_residence_visa: data.valid_residence_visa,
          currently_in_sri_lanka: data.currently_in_sri_lanka,
          multiple_entry_visa: data.multiple_entry_visa,
          srilanka_comp_details: data.srilanka_comp_details,
          company_zip_details: data.company_zip_details,
          company_city: data.company_city
        })
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong")
      navigate("/")

    }
  }, [id])


  useEffect(() => {
    fetchApplicationDetail()
  }, [fetchApplicationDetail])


  const imageHandle = (e) => {
    const { name, files } = e.target;
    if (!files) return;
    let formData = new FormData();
    console.log(" formData", formData);
    formData.append("image", files[0]);
    uploadFile({ endPoint: "/api/upload", formData })
      .then(res => {
        console.log(`${name} uploaded successfully`, res.file.filename);
        setdocument(prevImages => ({
          ...prevImages,
          [name]: res.file.filename
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  console.log("nabami pal", formik.initialValues.visa_categroy);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='gsrilanka'>
        <div className="centerCol">
          <div className="welcomeText">
            <Container className='pagesa4'>

              <p className='red'> All information should be entered as per the applicant's passport</p>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Please Select Your  Nationality <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select aria-label="Default Select example" name='nationality' value={formik.values.nationality} onChange={formik.handleChange} >
                    <option>Select Country</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Angola">Angola</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire Saint Eustatius and Saba">Bonaire Saint Eustatius and Saba</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Islands">Cocos Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Republic of the Congo">Republic of the Congo</option>
                    <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="France Metropolitan">France Metropolitan</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern">French Southern</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle Of Man">Isle Of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="North Korea">North Korea</option>
                    <option value="Northern Mariana">Northern Mariana</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory">Palestinian Territory</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Uk - British Dependant Territory Citizen">Uk - British Dependant Territory Citizen</option>
                    <option value="Uk - British Indian Ocean Territory">Uk - British Indian Ocean Territory</option>
                    <option value="Uk - British National (Overseas)">Uk - British National (Overseas)</option>
                    <option value="Uk - British Overseas Citizen">Uk - British Overseas Citizen</option>
                    <option value="Uk - British Protected Person">Uk - British Protected Person</option>
                    <option value="Uk - British Subject">Uk - British Subject</option>
                    <option value="Uk - British-Citizen (Great Britain)">Uk - British-Citizen (Great Britain)</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican">Vatican</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="British Virgin Islands">British Virgin Islands</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>


                  </Form.Select>
                </Col>

              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Country Of Residence <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select aria-label="Default Select example" name='country_of_residence' value={formik.values.country_of_residence} onChange={formik.handleChange}>
                    <option>Select Country</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Angola">Angola</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire Saint Eustatius and Saba">Bonaire Saint Eustatius and Saba</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Islands">Cocos Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Republic of the Congo">Republic of the Congo</option>
                    <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="France Metropolitan">France Metropolitan</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern">French Southern</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle Of Man">Isle Of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="North Korea">North Korea</option>
                    <option value="Northern Mariana">Northern Mariana</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory">Palestinian Territory</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Uk - British Dependant Territory Citizen">Uk - British Dependant Territory Citizen</option>
                    <option value="Uk - British Indian Ocean Territory">Uk - British Indian Ocean Territory</option>
                    <option value="Uk - British National (Overseas)">Uk - British National (Overseas)</option>
                    <option value="Uk - British Overseas Citizen">Uk - British Overseas Citizen</option>
                    <option value="Uk - British Protected Person">Uk - British Protected Person</option>
                    <option value="Uk - British Subject">Uk - British Subject</option>
                    <option value="Uk - British-Citizen (Great Britain)">Uk - British-Citizen (Great Britain)</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican">Vatican</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="British Virgin Islands">British Virgin Islands</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>


                  </Form.Select>
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Visa Type <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>


                  <Form.Select aria-label="Default Select example" name='visa_type' value={formik.values.visa_type} onChange={formik.handleChange} >
                    <option>Select</option>
                    <option value="Non-Resident">Non-Resident</option>
                  </Form.Select>
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Visa Category <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>


                  <Form.Select aria-label="Default Select example" name='visa_categroy' value={formik.values.visa_categroy} onChange={handleVisaSelectChange} >
                    <option>Select</option>
                    <option value="Business">Business</option>
                    <option value="Tourist">Tourist</option>
                  </Form.Select>
                </Col>
              </Row>




              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Purpose of Visit  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  {isBusinessVisa ? (
                    <Form.Select
                      aria-label="Default Select example"
                      name="purpose_of_visit"
                      value={formik.values.purpose_of_visit}
                      onChange={formik.handleChange}
                    >
                      <option>Select Purpose</option>
                      {businessOptions.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Select
                      aria-label="Default Select example"
                      name="purpose_of_visit"
                      value={formik.values.purpose_of_visit}
                      onChange={formik.handleChange}
                    >
                      <option>Select Purpose</option>
                      {touristOptions.map((value) => (
                        <option key={value} value={value}>{value}</option>
                      ))}
                    </Form.Select>
                  )}

                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Sub Visa Type <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>


                  <Form.Select aria-label="Default Select example" name='sub_visa_type' value={formik.values.sub_visa_type} onChange={formik.handleChange} >
                    <option>Select</option>
                    {isBusinessVisa ? Object.entries(businessVisaTime).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    )) :
                      Object.entries(touristVisaTime).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Type of Travel Document <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>


                  <Form.Select aria-label="Default Select example" name='type_of_travel_doc' value={formik.values.type_of_travel_doc} onChange={formik.handleChange} >
                    <option>Select</option>
                    <option value="yes"> Ordinary Passport</option>

                  </Form.Select>
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Email <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="email No."
                    name='email'
                    aria-describedby="basic-addon1"
                    // value={formData.email}
                    // onChange={handleInputChange}
                    value={formik.values.email}
                    onChange={formik.handleChange}

                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Country Code  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="country_code"
                    name='country_code'
                    aria-describedby="basic-addon1"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Mobile/ Cell Phone Number <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="Whatsapp No."
                    name='mobile_number'
                    aria-describedby="basic-addon1"
                    value={formik.values.mobile_number}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>





              <div className="formvisa_apply">
                Passport Details
              </div>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Passport Number <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control

                    aria-label="Whatsapp No."
                    name='passport_number'
                    aria-describedby="basic-addon1"

                    value={formik.values.passport_number}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Passport Issued Date <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="ggg"
                    type='date'
                    aria-describedby="basic-addon1"
                    name='passport_issued_date'
                    // value={formData.passport_issued_date} onChange={handleInputChange}
                    value={formik.values.passport_issued_date} onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>

                  <Form.Label htmlFor="basic-url">Passport Expiry Date <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control

                    aria-label="ggg"
                    type='date'
                    aria-describedby="basic-addon1"
                    name='passport_expiry_date'
                    // value={formData.passport_expiry_date} onChange={handleInputChange}
                    value={formik.values.passport_expiry_date} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Date of Birth <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control

                    aria-label="ggg"
                    type='date'
                    aria-describedby="basic-addon1"
                    name='date_of_birth'
                    //  value={formData.date_of_birth} onChange={handleInputChange}
                    value={formik.values.date_of_birth} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Title <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>

                  <Form.Select aria-label="Default Select example" name='title'
                    //  value={formData.title} onChange={handleInputChange}
                    value={formik.values.title} onChange={formik.handleChange}
                  >
                    <option> Select Title </option>
                    <option value="DR">DR</option>
                    <option value="MASTER">MASTER</option>
                    <option value="MISS">MISS</option>
                    <option value="MR">MR</option>
                    <option value="MRS">MRS</option>
                    <option value="MS">MS</option>
                    <option value="REV">REV</option>
                  </Form.Select>


                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Surname/Family Name <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // // placeholder="middle Name "
                    aria-label="surname "

                    aria-describedby="basic-addon1"
                    name="surname"
                    // value={formData.surname} onChange={handleInputChange}
                    value={formik.values.surname} onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Other/Given Names  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // // placeholder="Last Name "
                    aria-label="Last Name "
                    name='other_name'
                    aria-describedby="basic-addon1"
                    // value={formData.other_name}
                    // onChange={handleInputChange}
                    value={formik.values.other_name} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Passport Place of Issue  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // // placeholder="Last Name "
                    aria-label="Last Name "
                    name='passport_place_of_issue'
                    aria-describedby="basic-addon1"
                    // value={formData.passport_place_of_issue}
                    // onChange={handleInputChange}
                    value={formik.values.passport_place_of_issue} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Place of Birth  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // // placeholder="Last Name "
                    aria-label="Last Name "
                    name='place_of_birth'
                    aria-describedby="basic-addon1"
                    // value={formData.place_of_birth}
                    // onChange={handleInputChange}

                    value={formik.values.place_of_birth} onChange={formik.handleChange}
                  />
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Marital Status <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>

                  <Form.Select aria-label="Default Select example" name='marital_status'
                    // value={formData.marital_status} onChange={handleInputChange}
                    value={formik.values.marital_status} onChange={formik.handleChange}
                  >
                    <option>Select Status</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Widowed">Widowed</option>

                  </Form.Select>

                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Gender <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>

                  <Form.Select aria-label="Default Select example" name='gender'
                    // value={formData.gender} onChange={handleInputChange} 
                    value={formik.values.gender} onChange={formik.handleChange}
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>

                  </Form.Select>

                </Col>
              </Row>



              <div className="formvisa_apply">
                Travel Information
              </div>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Date of Arrival in Srianka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control

                    aria-label="ggg"
                    type='date'
                    aria-describedby="basic-addon1"
                    name='date_of_arrivalIn_srilanka'
                    //  value={formData.date_of_arrivalIn_srilanka} onChange={handleInputChange}
                    value={formik.values.date_of_arrivalIn_srilanka} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">  Port of Entry in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>

                  <Form.Select aria-label="Default Select example" name='port_of_entry_in_srilanka'
                    // value={formData.port_of_entry_in_srilanka} onChange={handleInputChange} 
                    value={formik.values.port_of_entry_in_srilanka} onChange={formik.handleChange}
                  >
                    <option>Select </option>
                    <option value="Bandaranaike International Airport">Bandaranaike International Airport</option>
                    <option value="Jaffna International Airport">Jaffna International Airport</option>
                    <option value="Mattala Rajapaksa International Airport">Mattala Rajapaksa International Airport</option>
                  </Form.Select>
                </Col>
              </Row>


              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> During Your Stay in Sri Lanka , your place of Stay will be  <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>

                  <Form.Select aria-label="Default Select example" name='stay_in_srilanka'
                    //  value={formData.stay_in_srilanka} onChange={handleInputChange} 
                    value={formik.values.stay_in_srilanka} onChange={formik.handleChange}
                  >
                    <option>Select </option>
                    <option value="Home Stay">Home Stay</option>
                    <option value="hotel">Hotel</option>
                    <option value="Lodge">Lodge</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Date of Proposed Departure From Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control

                    aria-label="ggg"
                    type='date'
                    aria-describedby="basic-addon1"
                    name='date_of_propos_dept_srilanka'
                    // value={formData.date_of_propos_dept_srilanka} onChange={handleInputChange}
                    value={formik.values.date_of_propos_dept_srilanka} onChange={formik.handleChange}
                  />
                </Col>
              </Row>


              <div>
                {isBusinessVisa ? <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Company Details in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      // placeholder="Whatsapp No."
                      aria-label="srilanka_comp_details"
                      name='srilanka_comp_details'
                      aria-describedby="basic-addon1"
                      // value={formData.srilanka_comp_details}
                      // onChange={handleInputChange}
                      value={formik.values.srilanka_comp_details} onChange={formik.handleChange}
                    />
                  </Col>
                </Row>
                  : ""}
                {isBusinessVisa ? <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Company Zip in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      // placeholder="Whatsapp No."
                      aria-label="company_zip_details"
                      name='company_zip_details'
                      aria-describedby="basic-addon1"
                      // value={formData.company_zip_details}
                      // onChange={handleInputChange}
                      value={formik.values.company_zip_details} onChange={formik.handleChange}
                    />
                  </Col>
                </Row> : ""}
                {
                  isBusinessVisa ? <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url"> Company City  in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        // placeholder="Whatsapp No."
                        aria-label="company_city"
                        name='company_city'
                        aria-describedby="basic-addon1"
                        // value={formData.company_city}
                        // onChange={handleInputChange}
                        value={formik.values.company_city} onChange={formik.handleChange}
                      />
                    </Col>
                  </Row> : ""
                }
              </div>

              <div className="formvisa_apply">
                Contact Details
              </div>



              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Address Line 1 in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="Whatsapp No."
                    name='address_line1'
                    aria-describedby="basic-addon1"
                    // value={formData.address_line1}
                    // onChange={handleInputChange}
                    value={formik.values.address_line1} onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Address Line 2 in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="Whatsapp No."
                    name='address_line2'
                    aria-describedby="basic-addon1"
                    // value={formData.address_line2}
                    // onChange={handleInputChange}
                    value={formik.values.address_line2} onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> City <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="Whatsapp No."
                    name='city'
                    aria-describedby="basic-addon1"
                    value={formik.values.city} onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> State <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="Whatsapp No."
                    name='state'
                    aria-describedby="basic-addon1"
                    // value={formData.state}
                    // onChange={handleInputChange}
                    value={formik.values.state} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Zip/Postal Code <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    // placeholder="Whatsapp No."
                    aria-label="Whatsapp No."
                    name='postal_code'
                    aria-describedby="basic-addon1"
                    // value={formData.postal_code}
                    // onChange={handleInputChange}
                    value={formik.values.postal_code} onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              <div className="formvisa_apply">
                Required Document
              </div>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Passport Size Colored Photo <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type='file'
                    aria-label=""
                    name='passport_size_photo'
                    onChange={imageHandle}
                  />
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url"> Passport Bio Page <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type='file'
                    aria-label=""
                    name='passport_bio_page'
                    onChange={imageHandle}
                  />
                </Col>
              </Row>

              <div>
                {formik.values.visa_categroy === "Business" || formik.initialValues.visa_categroy === "Business" ? <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url"> Invitation Letter from Host Company in Sri Lanka <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>{" "}*</span></Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type='file'
                      aria-label=""
                      name='invitation_latter'
                      onChange={imageHandle}
                    />
                  </Col>
                </Row> : null}


              </div>





              <p className='red'> Please consider information you entered before confirm</p>

              <Row className="justify-content-md-center m-2 etavisatype">
                <Form>

                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    className='kkkmmmsss'
                    onChange={handleCheckboxChange1}
                    checked={isChecked1}
                    label=" I would like to confirm the above information is correct. "
                  />
                </Form>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>

                </Col>
                <Col sm={4}>

                  <Button type='submit' disabled={!isChecked1} > {id ? "Update and Next" : "Submit"}  </Button>
                </Col>
              </Row>
            </Container>
          </div>

        </div>
        <ToastContainer />
      </div>
    </form>
  )
}

export default ApplyOne